<template>
  <div
    v-show="isShow"
    :class="['schedule-item', reservedClass]"
    :style="{ 'background-color': (schedule.clientType === 1 ? '#F1F5FF' : '#FFFFFF') }"
    @click="openModal()"
  >
    <strong>[{{ startTime }}]</strong> {{ schedule.title }}
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  props: {
    schedule: {
      type: Object,
      required: true,
    },
    isPast: {
      type: Boolean,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reservedClass: null,
      startTime: null,
    }
  },
  computed: {
    isShow() {
      if (this.viewType === '1') { return true }
      if (this.viewType === '2') { return this.schedule.seminarType === 1 && this.schedule.isReserved }
      if (this.viewType === '3') { return this.schedule.seminarType === 2 }
      return false
    },
  },
  watch: {
    schedule() {
      this.reservedClass = this.getReservedClass()
      this.startTime = dayjs(this.schedule.startDate).format('HH:mm')
    }
  },
  created() {
    this.reservedClass = this.getReservedClass()
    this.startTime = dayjs(this.schedule.startDate).format('HH:mm')
  },
  methods: {
    openModal() {
      if (!this.isPast) {
        this.$emit('openModal', {
          seminarType: this.schedule.seminarType,
          id: this.schedule.id,
          entry: 110
        })
      }
    },
    getReservedClass() {
      if (this.schedule.isCancel) { return 'past-month cancel-type' }
      if (this.isPast) { return 'past-month' }
      if (!this.schedule.isReserved) { return 'non-reserved' }
      if (this.schedule.seminarType === 1) { return 'my-seminar' }
      if (this.schedule.seminarType === 2) { return 'my-kma' }
      return ''
    },
  },
}
</script>
