var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("td", [
    _c("span", { class: _vm.dayClass }, [_vm._v(_vm._s(_vm.day.day))]),
    _c(
      "div",
      { staticClass: "schedule-box" },
      _vm._l(_vm.day.schedules, function (schedule) {
        return _c("CalendarSchedule", {
          key: schedule.key,
          attrs: {
            schedule: schedule,
            "is-past": _vm.day.isPast,
            "view-type": _vm.viewType,
          },
          on: { openModal: _vm.openModal },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }