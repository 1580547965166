<template>
  <div :class="['popup-seminar-detail', seminar.isCancel ? 'seminar-cancel' : '']">
    <button
      class="btn-close"
      style="z-index: 11;"
      @click.prevent="closeModal()"
    >
      닫기
    </button>
    <div class="imgBox" />
    <div
      v-if="callType === 'my'"
      class="my-seminar-type"
    >
      <label class="pharmacist">제약 웹 세미나</label>
    </div>
    <dl>
      <dt>{{ seminar.title }}</dt>
      <dd
        v-for="(item, index) in seminar.lecturerName.split(',')"
        :key="index"
      >
        {{ item }}
        <template v-if="(seminar.lecturerWorkPlace != null && seminar.lecturerWorkPlace.length > 0) || (seminar.lecturerMedicalDept != null && seminar.lecturerMedicalDept.length > 0)">
          ({{ seminar.lecturerWorkPlace != null && seminar.lecturerWorkPlace.length > 0 ? `${seminar.lecturerWorkPlace.split(',')[index]}${seminar.lecturerMedicalDept != null && seminar.lecturerMedicalDept.length > 0 ? ' ' : ''}` : '' }}{{ seminar.lecturerMedicalDept != null && seminar.lecturerMedicalDept.length > 0 ? `${seminar.lecturerMedicalDept.split(',')[index]}` : '' }})
        </template>
      </dd>
      <dd>{{ seminarDate }}</dd>
    </dl>
    <div class="bottom-fix">
      <p
        v-if="seminar.isPortalReserve && seminar.isPortalReserveApi"
        class="tip"
      >
        <span class="bu-type">※</span> 본 웹 세미나는 <span class="txt-blue">{{ seminar.clientName }} '{{ seminar.clientPortalName }}'</span> 에서 사전 예약을 진행하며, 사전 예약시 MY 세미나에 일정이 자동으로 등록됩니다.
      </p>
      <p
        v-else-if="seminar.isPortalReserve"
        class="tip"
      >
        <span class="bu-type">※</span> 본 웹 세미나는 <span class="txt-blue">{{ seminar.clientName }} '{{ seminar.clientPortalName }}'</span> 에서 사전 예약을 진행합니다.
      </p><p class="tip">
        <span class="bu-type">※</span> 일정을 MY 세미나에 등록했을 경우 시청 팝업 알림을 제공합니다.
      </p>

      <!-- 상품 1 프리패스 O / 사전예약 O -->
      <div v-if="seminar.isFreepass && seminar.isPortalReserveApi">
        <div
          v-if="seminar.isReserved"
          class="btn-wrap"
        >
          <button
            class="btn-line"
            :disabled="checkStartTime"
            @click="cancelSeminar"
          >
            {{ seminar.isPortalCancel ? '사전 예약 취소' : '사전 예약 완료' }}
          </button>
          <button
            class="btn-go-reservation"
            :disabled="checkStartTimeOneHour"
            @click="linkSeminar(115, 'watch')"
          >
            세미나 시청하기
          </button>
        </div>
        <div v-else>
          <button
            class="btn-go-reservation"
            :disabled="checkStartTime"
            @click="linkSeminar(114, 'reserve')"
          >
            사전 예약 하러 가기
          </button>
        </div>
      </div>

      <!-- 상품 2 프리패스 O / 사전예약 X -->
      <div v-else-if="seminar.isFreepass">
        <div class="btn-wrap">
          <button
            :class="seminar.isReserved ? 'btn-line' : 'btn-cancel'"
            :disabled="checkStartDay"
            @click="saveMySeminar"
          >
            {{ seminar.isReserved ? 'MY 세미나 등록 삭제' : 'MY 세미나 등록' }}
          </button>
          <button
            class="btn-go-reservation"
            @click="linkSeminar(checkStartTimeOneHour ? 113 : 115, checkStartTimeOneHour ? 'reserve' : 'watch')"
          >
            {{ checkStartTimeOneHour ? '자세히 보러가기' : '세미나 시청하기' }}
          </button>
        </div>
      </div>

      <!-- 상품 3 프리패스 X / 사전예약 X -->
      <div v-else>
        <div class="btn-wrap">
          <button
            :class="seminar.isReserved ? 'btn-line' : 'btn-cancel'"
            :disabled="checkStartDay"
            @click="saveMySeminar"
          >
            {{ seminar.isReserved ? 'MY 세미나 등록 삭제' : 'MY 세미나 등록' }}
          </button>
          <button
            class="btn-go-reservation"
            @click="linkSeminar(checkStartTimeOneHour ? 113 : 115, checkStartTimeOneHour ? 'reserve' : 'watch')"
          >
            {{ checkStartTimeOneHour ? '자세히 보러가기' : '세미나 시청하기' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
import { callExternalApi } from '@/core/global/global-liveinfo'
import { confirmPointMission } from '@/common/userInfo/service'
import { setStatistics } from '@/common/statistics/service'

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    seminar: {
      type: Object,
      required: true,
    },
    callType: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      startDateTime: null,
      seminarType: 1,
    }
  },
  computed: {
    seminarDate() {
      const dayOfWeek = dayjs(this.seminar.startDate).format('d')
      const date = dayjs(this.seminar.startDate).format('YYYY-MM-DD')

      const startTime = dayjs(this.seminar.startDate).format('HH:mm')
      const endTime = this.seminar.endDate ? dayjs(this.seminar.endDate).format('HH:mm') : ''

      return `${date} (${this.getDayOfWeekName(dayOfWeek)}) ${startTime} ~ ${endTime}`
    },
    checkStartTimeOneHour() {
      const startTime = dayjs(this.seminar.startDate).format('YYYY-MM-DD HH:mm')
      const currentTime = dayjs().format('YYYY-MM-DD HH:mm')

      const diffTime = dayjs(currentTime).diff(startTime, 'minute')

      return diffTime < -60
    },
    checkStartTime() {
      const startTime = dayjs(this.seminar.startDate).format('YYYY-MM-DD HH:mm')
      const currentTime = dayjs().format('YYYY-MM-DD HH:mm')

      const diffTime = dayjs(currentTime).diff(startTime, 'minute')

      return diffTime >= 0
    },
    checkStartDay() {
      const startDay = dayjs(this.seminar.startDate).format('YYYY-MM-DD')
      const currentDay = dayjs().format('YYYY-MM-DD')

      const diffDay = dayjs(currentDay).diff(startDay, 'day')

      return diffDay > -1
    },
    ...mapGetters({
      userName: 'account/getName',
    }),
  },
  methods: {
    redirectLogin() {
      this.$router.push({ name: 'login-main', query: { redirect: 'app-webseminar-client' }, params: { id: this.id, seminarType: 1 } }).catch(() => {})
    },
    linkSeminar(statisticsType, type) {
      setStatistics(statisticsType, this.id)

      if (this.userName || !this.seminar.isFreepass) {
        if (type === 'reserve') {
          if (this.seminar.reverseLink) this.link('reserve')
          else this.$parent.showAlertDialog('세미나 사전 예약 링크가 존재하지 않습니다.')
        } else if (type === 'watch') {
          if (this.seminar.watchLink) this.link('watch')
          else this.$parent.showAlertDialog('세미나 시청 링크가 존재하지 않습니다.')
        }
      } else {
        this.redirectLogin()
      }
    },
    link(type) {
      if (this.seminar.isFreepass) {
        axios.get(`/fu/webseminar/client/${this.id}/link`, {
          params: {
            type,
          },
        })
          .then(rs => {
            callExternalApi("LinkWebSeminar", JSON.stringify({ WebSeminarId: this.id, Url: rs.data }))

            this.$emit('closeModal', 1)
          })
          .catch(() => {
            this.$parent.$parent.showAlertDialog('사전예약 주소 조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
          })
      } else {
        callExternalApi("LinkWebSeminar", JSON.stringify({
          webSeminarId: this.id,
          url: type === 'reserve' ? this.seminar.reverseLink : this.seminar.watchLink
        }))
      }
    },
    cancelSeminar() {
      if (this.userName) {
        if (this.seminar.isFreepass) this.link('reserve')
        else this.$parent.showAlertDialog('세미나 사전 예약 링크가 존재하지 않습니다.')
      } else {
        this.redirectLogin()
      }
    },
    closeModal() {
      this.$emit('closeModal', 1)
    },
    reloadModal() {
      this.$emit('reloadModal', 1)
    },
    reloadDays(reserve) {
      this.$emit('reloadDays', dayjs(this.seminar.startDate).format('YYYYMMDD'), this.id, reserve)
    },
    getDayOfWeekName(dayOfWeek) {
      if (dayOfWeek === '0') { return '일' }
      if (dayOfWeek === '1') { return '월' }
      if (dayOfWeek === '2') { return '화' }
      if (dayOfWeek === '3') { return '수' }
      if (dayOfWeek === '4') { return '목' }
      if (dayOfWeek === '5') { return '금' }
      if (dayOfWeek === '6') { return '토' }

      return ''
    },
    saveMySeminar() {
      if (this.seminar.isReserved === false) setStatistics(112, this.id)

      if (this.userName) {
        if (this.seminar.isReserved) this.cancelMySeminar()
        else this.registMySeminar()
      } else {
        this.redirectLogin()
      }
    },
    registMySeminar() {
      axios.post('/fu/webseminar/my', {
        webSeminarId: this.id,
        seminarType: this.seminarType
      })
        .then(() => {
          // this.reloadModal()
          this.seminar.isReserved = true

          this.reloadDays(true)

          confirmPointMission(14)
        })
        .catch(() => {
          this.$parent.$parent.showAlertDialog('MY 세미나 저장에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    cancelMySeminar() {
      this.$parent.$parent.showConfirmDialog('해당 일정을 MY 세미나에서 삭제하시겠습니까?', confirm => {
        if (confirm) {
          axios.delete('/fu/webseminar/my', {
            data: {
              webSeminarId: this.id,
              seminarType: this.seminarType
            }
          })
            .then(() => {
              this.closeModal()
              this.reloadDays(false)
            })
            .catch(() => {
              this.$parent.$parent.showAlertDialog('MY 세미나 삭제에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
            })
        }
      })
    }
  },
}
</script>
