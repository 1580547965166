<template>
  <td>
    <span :class="dayClass">{{ day.day }}</span>
    <div class="schedule-box">
      <CalendarSchedule
        v-for="schedule in day.schedules"
        :key="schedule.key"
        :schedule="schedule"
        :is-past="day.isPast"
        :view-type="viewType"
        @openModal="openModal"
      />
    </div>
  </td>
</template>

<script>
import CalendarSchedule from './Schedule.vue'

export default {
  components: {
    CalendarSchedule,
  },
  props: {
    day: {
      type: Object,
      required: true,
    },
    viewType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dayClass: null,
    }
  },
  created() {
    this.dayClass = this.getDayClass()
  },
  methods: {
    openModal(seminar) {
      this.$emit('openModal', seminar)
    },
    getDayClass() {
      if (this.day.isPrevMonth) { return 'prev-day' }
      if (this.day.isNextMonth) { return 'next-day' }
      if (this.day.isToday) { return 'today' }
      return 'day'
    },
  },
}
</script>
