var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isShow,
          expression: "isShow",
        },
      ],
      class: ["schedule-item", _vm.reservedClass],
      style: {
        "background-color":
          _vm.schedule.clientType === 1 ? "#F1F5FF" : "#FFFFFF",
      },
      on: {
        click: function ($event) {
          return _vm.openModal()
        },
      },
    },
    [
      _c("strong", [_vm._v("[" + _vm._s(_vm.startTime) + "]")]),
      _vm._v(" " + _vm._s(_vm.schedule.title) + " "),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }