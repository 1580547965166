var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "popup-seminar-detail",
        _vm.seminar.isCancel ? "seminar-cancel" : "",
      ],
    },
    [
      _c(
        "button",
        {
          staticClass: "btn-close",
          staticStyle: { "z-index": "11" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.closeModal()
            },
          },
        },
        [_vm._v(" 닫기 ")]
      ),
      _c("div", { staticClass: "imgBox" }),
      _vm.callType === "my"
        ? _c("div", { staticClass: "my-seminar-type" }, [
            _c("label", { staticClass: "pharmacist" }, [
              _vm._v("제약 웹 세미나"),
            ]),
          ])
        : _vm._e(),
      _c(
        "dl",
        [
          _c("dt", [_vm._v(_vm._s(_vm.seminar.title))]),
          _vm._l(_vm.seminar.lecturerName.split(","), function (item, index) {
            return _c(
              "dd",
              { key: index },
              [
                _vm._v(" " + _vm._s(item) + " "),
                (_vm.seminar.lecturerWorkPlace != null &&
                  _vm.seminar.lecturerWorkPlace.length > 0) ||
                (_vm.seminar.lecturerMedicalDept != null &&
                  _vm.seminar.lecturerMedicalDept.length > 0)
                  ? [
                      _vm._v(
                        " (" +
                          _vm._s(
                            _vm.seminar.lecturerWorkPlace != null &&
                              _vm.seminar.lecturerWorkPlace.length > 0
                              ? "" +
                                  _vm.seminar.lecturerWorkPlace.split(",")[
                                    index
                                  ] +
                                  (_vm.seminar.lecturerMedicalDept != null &&
                                  _vm.seminar.lecturerMedicalDept.length > 0
                                    ? " "
                                    : "")
                              : ""
                          ) +
                          _vm._s(
                            _vm.seminar.lecturerMedicalDept != null &&
                              _vm.seminar.lecturerMedicalDept.length > 0
                              ? "" +
                                  _vm.seminar.lecturerMedicalDept.split(",")[
                                    index
                                  ]
                              : ""
                          ) +
                          ") "
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            )
          }),
          _c("dd", [_vm._v(_vm._s(_vm.seminarDate))]),
        ],
        2
      ),
      _c("div", { staticClass: "bottom-fix" }, [
        _vm.seminar.isPortalReserve && _vm.seminar.isPortalReserveApi
          ? _c("p", { staticClass: "tip" }, [
              _c("span", { staticClass: "bu-type" }, [_vm._v("※")]),
              _vm._v(" 본 웹 세미나는 "),
              _c("span", { staticClass: "txt-blue" }, [
                _vm._v(
                  _vm._s(_vm.seminar.clientName) +
                    " '" +
                    _vm._s(_vm.seminar.clientPortalName) +
                    "'"
                ),
              ]),
              _vm._v(
                " 에서 사전 예약을 진행하며, 사전 예약시 MY 세미나에 일정이 자동으로 등록됩니다. "
              ),
            ])
          : _vm.seminar.isPortalReserve
          ? _c("p", { staticClass: "tip" }, [
              _c("span", { staticClass: "bu-type" }, [_vm._v("※")]),
              _vm._v(" 본 웹 세미나는 "),
              _c("span", { staticClass: "txt-blue" }, [
                _vm._v(
                  _vm._s(_vm.seminar.clientName) +
                    " '" +
                    _vm._s(_vm.seminar.clientPortalName) +
                    "'"
                ),
              ]),
              _vm._v(" 에서 사전 예약을 진행합니다. "),
            ])
          : _vm._e(),
        _vm._m(0),
        _vm.seminar.isFreepass && _vm.seminar.isPortalReserveApi
          ? _c("div", [
              _vm.seminar.isReserved
                ? _c("div", { staticClass: "btn-wrap" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-line",
                        attrs: { disabled: _vm.checkStartTime },
                        on: { click: _vm.cancelSeminar },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.seminar.isPortalCancel
                                ? "사전 예약 취소"
                                : "사전 예약 완료"
                            ) +
                            " "
                        ),
                      ]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn-go-reservation",
                        attrs: { disabled: _vm.checkStartTimeOneHour },
                        on: {
                          click: function ($event) {
                            return _vm.linkSeminar(115, "watch")
                          },
                        },
                      },
                      [_vm._v(" 세미나 시청하기 ")]
                    ),
                  ])
                : _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "btn-go-reservation",
                        attrs: { disabled: _vm.checkStartTime },
                        on: {
                          click: function ($event) {
                            return _vm.linkSeminar(114, "reserve")
                          },
                        },
                      },
                      [_vm._v(" 사전 예약 하러 가기 ")]
                    ),
                  ]),
            ])
          : _vm.seminar.isFreepass
          ? _c("div", [
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    class: _vm.seminar.isReserved ? "btn-line" : "btn-cancel",
                    attrs: { disabled: _vm.checkStartDay },
                    on: { click: _vm.saveMySeminar },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.seminar.isReserved
                            ? "MY 세미나 등록 삭제"
                            : "MY 세미나 등록"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-go-reservation",
                    on: {
                      click: function ($event) {
                        return _vm.linkSeminar(
                          _vm.checkStartTimeOneHour ? 113 : 115,
                          _vm.checkStartTimeOneHour ? "reserve" : "watch"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.checkStartTimeOneHour
                            ? "자세히 보러가기"
                            : "세미나 시청하기"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ])
          : _c("div", [
              _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    class: _vm.seminar.isReserved ? "btn-line" : "btn-cancel",
                    attrs: { disabled: _vm.checkStartDay },
                    on: { click: _vm.saveMySeminar },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.seminar.isReserved
                            ? "MY 세미나 등록 삭제"
                            : "MY 세미나 등록"
                        ) +
                        " "
                    ),
                  ]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn-go-reservation",
                    on: {
                      click: function ($event) {
                        return _vm.linkSeminar(
                          _vm.checkStartTimeOneHour ? 113 : 115,
                          _vm.checkStartTimeOneHour ? "reserve" : "watch"
                        )
                      },
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.checkStartTimeOneHour
                            ? "자세히 보러가기"
                            : "세미나 시청하기"
                        ) +
                        " "
                    ),
                  ]
                ),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "tip" }, [
      _c("span", { staticClass: "bu-type" }, [_vm._v("※")]),
      _vm._v(" 일정을 MY 세미나에 등록했을 경우 시청 팝업 알림을 제공합니다. "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }